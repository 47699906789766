.input {
    width: 100%;
    box-sizing: border-box;
    font: inherit;

    padding: 0.5em;
    border: 1px solid black;
    border-radius: 3px;

    outline: none;
    box-shadow: inset 0 1px 2px #b6b4af, inset -1px 0 0 rgba(255, 255, 255, 0.15),
        inset 1px 0 0 rgba(255, 255, 255, 0.15), 0 1px 0 white;

    &:focus {
        border-color: #326fb2;
        box-shadow: inset 0 1px 2px #b6b4af, inset -1px 0 0 rgba(255, 255, 255, 0.15),
            inset 1px 0 0 rgba(255, 255, 255, 0.15), 0 0 3px 1px #92badd;
    }

    &:disabled {
        background: #fafafa;
        border-color: #888;
        color: #333;
    }
}
