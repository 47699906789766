$mobile: 728px;

.ad {
    background: #f0f0f0;
    position: relative;
    border-radius: 2px;
    border: 1px solid #ececec;
    width: 100%;
    padding: 0.5em;
    margin: 1em 0;
    text-align: center;
    box-sizing: border-box;
}

.inner {
    display: block;
    margin: auto;
    max-width: 100%;
    overflow: hidden;
}

/* stylelint-disable a11y/no-display-none */
.label {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.3em 0.5em;
    color: #aaa;
    background: #dfdfdf;
    border-top-left-radius: 3px;
    font-size: 0.8em;

    /* TODO: reenable this at some point */
    display: none;
}

.close {
    float: right;
    background: #f0f0f0;
    border: none;
    padding: 0.25em 0.5em 0.25em 0.6em;
    cursor: pointer;
    color: #888;
    font-size: 1em;
    border-bottom-left-radius: 7px;

    position: absolute;
    top: 0;
    right: 0;

    svg {
        height: 1.1em;
    }

    @media screen and (max-width: $mobile) {
        height: 32px;
        width: 32px;
    }

    @media screen and (max-width: 400px) {
        top: -32px;
        padding: 0.25em;
        right: 0.5em;
        border-radius: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #ddd;
        border-bottom: none;
    }
}

.no_mobile {
    @media screen and (max-width: $mobile) {
        display: none;
    }
}

.no_desktop {
    @media screen and (min-width: $mobile) {
        display: none;
    }
}

.ad_top {
    composes: ad;

    margin: 0;
    margin-bottom: 2em;
    width: none;

    .inner {
        width: 728px;
        height: 90px;

        @media screen and (max-width: $mobile) {
            width: 350px;
            height: 50px;
        }
    }
}

.ad_flexa {
    composes: ad no_mobile;
    width: 100%;
    margin-bottom: 1em;

    .inner {
        width: 300px;
        height: 600px;
    }
}

.ad_right {
    composes: ad no_desktop;
    margin: 1em 0;
    overflow: hidden;
    .inner {
        width: 300px;
        height: 250px;
    }
}

.ad_right_b {
    composes: ad;
    margin: 1em 0;
    overflow: hidden;
    .inner {
        width: 320px;
        height: 50px;
    }
}

.ad_bottom {
    composes: ad;

    margin: 0;
    margin-top: 1em;
    border: none;
    border-top: 1px solid #ddd;

    .inner {
        width: 728px;
        height: 90px;

        @media screen and (max-width: $mobile) {
            width: 320px;
            height: 50px;
        }
    }
}

.sticky {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
}

.ad_video {
    overflow: hidden;
    height: 360px;

    @media screen and (max-width: $mobile) {
        display: none;
    }
}
