.noItems {
    padding: 6px;
}

.external {
    position: relative;
    top: 0.2rem;
    width: 1rem;
    margin-left: 0.5rem;
    forced-color-adjust: auto;
}
