$mobile: 600px;

a.skittle {
    font-size: 11px;
    height: 14px;
    line-height: 14px;

    padding: 0 4px;
    margin: 0 0.5em;

    text-align: center;
    border: 1px solid transparent;
    position: relative;
    border-radius: 20px;
    background: #346ea2;
    color: white;

    &:hover,
    &:focus {
        text-decoration: none;
        color: white;
        background: #589bd6;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;

    h3 {
        flex-shrink: 1;
    }

    @media (min-width: 700px) and (max-width: 950px) {
        display: block;
        flex-wrap: wrap;

        .forSale {
            display: inline-block;
            margin-bottom: 0.5em;
            text-align: left;
        }
    }
}

.mpData {
    margin-bottom: 16px;
}

.buttons {
    display: flex;

    a {
        width: 100%;
        background-color: #00800b !important;
        + a {
            margin-left: 0.5rem;
        }
    }

    @media (max-width: 352px), (min-width: 700px) and (max-width: 1176px) {
        flex-direction: column;

        a {
            width: auto;

            + a {
                margin-left: 0;
                margin-top: 0.3rem;
            }
        }
    }
}

.sellLink {
    width: 40%;
    text-align: right;
    margin-right: 8px;
}

.external {
    position: relative;
    top: 0.2rem;
    width: 1rem;
    margin-left: 0.5rem;
    forced-color-adjust: auto;
}

.paragraph {
    line-height: 1.2rem;
}
