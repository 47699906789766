.wrapper {
    margin-top: 0.5rem;

    @media (max-width: 675px) {
        /* stylelint-disable-next-line a11y/no-display-none */
        display: none;
        visibility: none;
    }
}

.icon {
    margin-right: 0.25rem;
}

.content {
    padding: 0.35em;
    display: flex;
    justify-content: space-between;
}

.link {
    padding: 0.5em 1em;
    border-radius: 3px;
    background: #ddd;
    color: white;
    text-decoration: none !important;
    white-space: nowrap;

    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.05), inset -1px 0 0 rgba(255, 255, 255, 0.025),
        inset 1px 0 0 rgba(255, 255, 255, 0.025), 0 1px 1px rgba(0, 0, 0, 0.1);

    svg {
        color: white;
        height: 1em;
        width: 1em;
        margin-right: 0.5em;
        display: inline-block;
        position: relative;
        top: 0.2em;
    }

    &:focus,
    &:hover {
        color: white;
    }
}

.twitter {
    composes: link;
    background: hsl(202, 78%, 41%);

    &:focus,
    &:hover {
        background: hsl(202, 78%, 48%);
    }
}

.facebook {
    composes: link;
    background: hsl(214, 89%, 41%);
    margin-right: 0.25em;

    &:focus,
    &:hover {
        background: hsl(214, 89%, 48%);
    }
}
