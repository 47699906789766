.simple {
    list-style: none;
    padding: 0;
    margin: 0;
}

.add {
    margin-left: 1em;
    flex-shrink: 0;
    align-self: flex-end;

    &:focus-visible {
        outline: 2px solid #195fcc;
    }
}

.remove {
    line-height: 1em;
}

h2.title {
    flex-grow: 0;
    width: auto;
}
