ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.review {
    display: flex;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
}

.bezel {
    display: inline-block;
    line-height: 0;
    position: relative;

    img {
        display: block;
    }

    &::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom-color: rgba(0, 0, 0, 0.15);
        border-top-color: rgba(0, 0, 0, 0.05);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }
}

.pic {
    margin-right: 0.5em;
    composes: bezel;
    min-width: 50px;
    width: 50px;
    height: @width;
    display: inline-block;
    background: #eee;

    text-align: center;
    line-height: @width;
    overflow: hidden;

    img {
        width: 50px;
        height: @width;
    }

    svg {
        width: 25px;
        height: 25px;
        color: #ccc;
        margin-top: 12px;
    }
}

.content {
    overflow-wrap: anywhere;
    flex-shrink: 1;
    flex-grow: 1;
}

.header {
    padding-top: 0.5em;
    position: relative;
}

.username {
    font-weight: bold;
}

.created {
    font-size: 0.92em;
}

.button {
    margin-right: 2em !important;
    outline: none;
}

.edited {
    margin-top: 0.5em;
    margin-bottom: 0;
    font-style: italic;
}

.rating {
    display: block;
    margin-top: 0.5em;
}

.replies {
    padding-left: 60px;

    .replies {
        padding-left: 0;
    }
}

.dropdown {
    position: absolute;
    top: 0;
    right: 0;

    & > button {
        border: none;
        background: none;
        outline: none;
        font: inherit;
        color: var(--grey70);
        cursor: pointer;

        svg {
            height: 20px;
        }
    }

    :focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
    }

    .deleteReview {
        position: relative;
        z-index: 1;
    }
    ul {
        position: absolute;
        top: 2.5em;
        right: -8px;
        border: 1px solid #ccc;
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        box-shadow: rgba(0, 0, 0, 0.2) 0 5px 15px 0;
        background: white;

        &::before {
            content: '';
            position: absolute;
            top: -21px;
            right: 11px;
            border: 10px solid transparent;
            border-bottom-color: #ccc;
        }

        &::after {
            content: '';
            position: absolute;
            top: -19px;
            right: 11px;
            border: 10px solid transparent;
            border-bottom-color: white;
            z-index: 100;
        }
    }

    li {
        white-space: nowrap;
        border-bottom: 1px solid #dfdfdf;

        svg {
            height: 1em;
            vertical-align: middle;
            margin-right: 0.5em;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    li a,
    li button {
        display: block;
        box-sizing: border-box;
        color: inherit !important;
        padding: 0.6em 0.5em !important;
        width: 100%;
        text-align: left;
        border: none;
        background: none;
        outline: none;
        font: inherit;
        cursor: pointer;
        border-radius: 0;
        margin: 0;

        &:focus,
        &:hover {
            color: inherit;
            text-decoration: underline;
            background: #f1f1f1 !important;
        }
    }

    li:first-child {
        a,
        button {
            &:hover,
            &:focus {
                &::after {
                    content: '';
                    position: absolute;
                    border: 10px solid transparent;
                    border-bottom-color: #f1f1f1;
                    top: -19px;
                    right: 11px;
                    z-index: 101;
                    pointer-events: none;
                }
            }
        }
    }
}
