.table {
    margin-top: 0.75rem;
    border-spacing: 0;

    tr {
        border: none;
    }

    th {
        width: 0;
        text-align: left;
        padding-right: 1.5rem;
        white-space: nowrap;
        vertical-align: top;
        font-weight: normal;
        padding-bottom: 0.25rem;
    }

    td {
        vertical-align: top;
    }

    @media (max-width: 600px) {
        th {
            margin-top: 4px;
            padding-bottom: 2px;
        }

        tr {
            display: flex;
            flex-direction: column;
            border: none;
        }
    }
}
