.preview {
    margin-bottom: 1em;

    strong {
        display: block;
        margin-left: 0.5em;
        margin-bottom: 0.1em;
    }
}

.content {
    overflow-wrap: anywhere;
    border: 1px solid #e5e5e5;
    padding: 0.5em;
}
