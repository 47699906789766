.forSale {
    display: block;
    text-align: center;
    flex-shrink: 1;
    width: 100%;
    white-space: nowrap;
}

.price {
    color: #c00;
}
