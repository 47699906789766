.spinner {
    animation: spin 1s infinite steps(8, start);

    width: 1em;
    height: @width;

    opacity: 0;
    transition: opacity 200ms;

    @media (prefers-reduced-motion: reduce) {
        display: none;
        animation: none;
        transition: none;
    }
}

.large {
    width: 2em;
    height: @width;
}

.show {
    opacity: 1;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
