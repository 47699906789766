/* stylelint-disable no-descending-specificity */

.notSafeForWorkImageContainer {
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #0a2937;
    background-image: url('sensitive-img.svg');
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nsfwLarge {
    composes: notSafeForWorkImageContainer;
    height: 600px;
    width: 600px;
    padding: 1rem;
    aspect-ratio: 1;
    gap: 1.25rem;
    max-height: calc(100vh - 150px - 5em);

    p {
        margin: 0;
    }
}

.nsfwSmall {
    composes: notSafeForWorkImageContainer;
    min-height: 150px;
    width: 150px;
    padding: 0.25rem;
    gap: 1rem;
}

.nsfwSmallNoButton {
    composes: nsfwSmall;
    height: 150px;
    border: 5px solid transparent;
}

.nsfwSmallThumbnail {
    composes: notSafeForWorkImageContainer;
    width: 90px;
    aspect-ratio: 1;
    padding: 0.25rem;
    text-wrap: wrap;

    p {
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-top: 0.25rem;
        margin-bottom: 0;
    }
}
.nswfLargeHeadline {
    font-size: 1.75rem;
    line-height: 2rem;
    color: white;
    text-align: center;
    text-wrap: wrap;
}

.nsfwSmallHeadline {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    color: white;
    text-align: center;
}

@media (max-width: 640px) {
    .nsfwLarge {
        padding: 0;
        width: calc(100vw - (1em + 0.75rem + 4px));
    }
}

.seeWhyButton {
    border: 1px solid #cecdc6;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 20px;
    margin: 0;
    padding: 0.75rem 1rem;
    background-color: transparent;
    color: white;
    cursor: pointer;
    width: fit-content;
}

.seeWhyButton:hover,
.seeWhyButton:focus,
.seeWhyButton:active,
.seeWhyButton:visited:hover {
    text-decoration: underline;
}

.seeWhyButtonSmall {
    composes: seeWhyButton;
    box-sizing: border-box;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}

/* Enhanced for modern browsers. Older unsupported browsers could see the text decoration */
a:has(.notSafeForWorkImageContainer),
a:has(.notSafeForWorkImageContainer):visited,
a:has(.notSafeForWorkImageContainer):active,
a:has(.notSafeForWorkImageContainer):focus,
a:has(.notSafeForWorkImageContainer):visited:hover,
a:has(.notSafeForWorkImageContainer):hover {
    text-decoration: none;
}
