.editor {
    margin-bottom: 2em;
    width: 100%;
}

.input {
    width: 100%;
    height: 6em;
    margin-bottom: 0.5em;
    resize: vertical;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action {
    display: flex;
}

.submit {
    margin-right: 1em;
}

.error {
    color: red;
}

.count {
    color: #555;
    margin-bottom: 0.75em;
    padding-left: 0.25em;
    font-style: italic;

    svg {
        width: 12px;
        margin-right: 0.35em;
        position: relative;
        top: 1px;
    }
}
