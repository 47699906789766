.id {
    background: none;
    border: none;
    font: inherit;

    font-size: 0.8em;
    cursor: pointer;
    flex-shrink: 0;

    position: relative;
    top: 0.3em;

    &:active {
        color: black;
    }
    span {
        user-select: all;
    }
}

.tooltip {
    visibility: hidden;

    .id:focus &,
    .id:hover & {
        visibility: visible;
    }

    font-size: 11px;
    background: black;
    color: white;

    position: absolute;
    top: -28px;
    text-align: center;
    right: -0.5rem;

    width: auto;
    height: 18px;
    line-height: @height;
    padding: 0 8px;

    white-space: nowrap;
    pointer-events: none;

    &::before {
        content: '';
        width: 0;
        height: 0;

        position: absolute;
        top: 18px;
        right: 2em;

        border-width: 5px 5px 0;
        border-color: transparent;
        border-style: solid;
        border-top-color: black;
    }
}
