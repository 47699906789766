.body {
    display: grid;
    margin-bottom: 1em;

    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-columns: 150px 1fr;
    grid-template-rows: auto 1fr;
}

.thumbnail {
    grid-area: 1 / 1 / 3 / 2;
    text-align: center;
}

.info {
    grid-area: 2 / 2 / 3 / 3;
    margin-left: 1.5rem;
    font-size: 0.9rem;
    line-height: 1;
}

@media screen and (max-width: 550px) {
    .body {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .thumbnail {
        display: flex;
        order: 2;
        margin-bottom: 1rem;

        img {
            height: auto;
        }
    }

    .title {
        order: 1;
        padding: 10px 0;
    }

    .info {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        order: 3;
    }
}
