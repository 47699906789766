.form {
    width: 32em;

    div {
        margin-bottom: 1em;
    }

    select {
        display: block;
    }

    input[type='radio'] {
        margin: 0 0.5em;
    }

    button {
        margin-right: 0.5em;
    }
}

.disabled {
    color: #888;
}

.loading {
    color: #aaa;
}
