.modal {
    padding: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 20px;
    display: inline-block;

    margin: auto;
    height: auto;
    flex-grow: 0;
    border-radius: 2px;
    border: 1px solid #dfdfdf;
    background: white;
    font-size: 0.9rem;
}

.title {
    margin: 0;
    margin-bottom: 0.5em;
    padding-bottom: 0.3em;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    min-height: 1.3em;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 9999;
}

.close {
    float: right;
    background: none;
    border: none;
    color: black;
    font-size: 0.9rem;
    top: -2px;
    position: relative;
    cursor: pointer;

    svg {
        height: 1em;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 0.8rem;

    button {
        margin-right: 8px;
    }
}
