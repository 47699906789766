.bezel {
    display: inline-block;
    line-height: 0;
    position: relative;

    img {
        display: block;
    }

    &::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        border: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom-color: rgba(0, 0, 0, 0.15);
        border-top-color: rgba(0, 0, 0, 0.05);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }
}

.image {
    composes: bezel;
}

.fallback {
    composes: bezel;

    display: flex;
    align-items: center;

    background-color: #e6eaed;
    position: relative;

    svg {
        color: #9b9b9b;
        margin: auto;
        width: 70%;
        max-width: 120px;
    }
}
