.markup {
    overflow-wrap: anywhere;
    line-height: 18px;
    max-width: 650px;

    blockquote {
        border: 1px solid #e5e5e5;
        padding: 0.5em;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
        border-radius: 4px;
        margin: 0;
    }
}
