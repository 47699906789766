.star {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font: inherit;
    height: 1.4rem;
    vertical-align: middle;
}

.icon {
    color: #6c6b68;
    width: 1rem;
}

.fill {
    color: #e56000;
}

.remove {
    border: none;
    background: none;
    padding: 0;
    color: #6c6b68;
    opacity: 1;
    cursor: pointer;
    height: 1.4em;
    vertical-align: middle;
    margin-left: 0.5em;

    & svg {
        height: 1.4em;
        opacity: 1;
        transform: scale(1);
        transition: opacity 400ms, transform 400ms;
        position: relative;
        top: -1px;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &[disabled] svg {
        opacity: 0;
        transform: scale(0.6);
    }
}
