.container {
    position: relative;
    display: inline-block;
}

.tooltip {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    z-index: 100;
    background: white;
    position: absolute;

    width: 280px;
    min-height: 2em;

    box-sizing: border-box;
    padding: 0.2em;

    box-shadow: rgba(0, 0, 0, 0.247) 0 0 20px 0, rgba(0, 0, 0, 0.247) 0 0 2px 0;

    h3 {
        margin: 0;
        padding: 0.5em 0.75em;
        font-size: 16px;
        border-bottom: 1px solid #d9d9d9;
    }

    .content {
        padding: 0.5em 0.75em;
        line-height: 20px;
    }
    .close {
        color: black;
        font-size: inherit;

        background: none;
        border: none;

        position: absolute;
        right: 0.25em;
        top: 0.5em;

        cursor: pointer;

        svg {
            width: 1.2em;
            height: 1.2em;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;

        width: 0;
        height: 0;
        border: 10px solid transparent;
    }
}

.right {
    left: 100%;
    margin-left: 1em;

    top: 0;
    margin-top: -0.75em;

    &::before {
        top: 0.75em;
        left: -20px;
        border-right-color: #d6d5d1;
    }

    &::after {
        top: 0.75em;
        left: -19px;
        border-right-color: white;
    }
}

.bottom {
    top: 100%;
    margin-top: 1em;

    left: 0;

    &::before {
        top: -21px;
        left: 0.5em;
        border-bottom-color: #d6d5d1;
    }

    &::after {
        top: -20px;
        left: 0.5em;
        border-bottom-color: white;
    }
}

.top {
    bottom: 100%;
    margin-bottom: 1em;

    left: 0;
    margin-top: -0.75em;

    &::before {
        bottom: -21px;
        left: 0.5em;
        border-top-color: #d6d5d1;
    }

    &::after {
        bottom: -20px;
        left: 0.5em;
        border-top-color: white;
    }
}

.top_end {
    bottom: 100%;
    margin-bottom: 1em;

    right: 0;
    margin-top: -0.75em;

    &::before {
        bottom: -21px;
        right: 0.5em;
        border-top-color: #d6d5d1;
    }

    &::after {
        bottom: -20px;
        right: 0.5em;
        border-top-color: white;
    }
}

.left {
    right: 100%;
    margin-right: 1em;

    top: 0;
    margin-top: -0.5em;

    &::before {
        top: 0.5em;
        right: -21px;
        border-left-color: #d6d5d1;
    }

    &::after {
        top: 0.5em;
        right: -20px;
        border-left-color: white;
    }
}

.bottom_end {
    top: 100%;
    margin-top: 1em;
    right: 0;

    &::before {
        top: -21px;
        right: 0.5em;
        border-bottom-color: #d6d5d1;
    }

    &::after {
        top: -20px;
        right: 0.5em;
        border-bottom-color: white;
    }

    @media (max-width: 600px) {
        left: 100%;
        margin-left: 1em;
        top: 0;
        margin-top: -0.75em;

        &::before {
            top: 0.75em;
            right: 0;
            left: -20px;
            border-right-color: #d6d5d1;
        }

        &::after {
            top: 0.75em;
            right: 0;
            left: -19px;
            border-right-color: white;
        }
    }
}
