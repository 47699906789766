.link {
    position: relative;
}

.inner {
    line-height: 1.5rem;
}

.inner h4 {
    margin: 0;
    margin-top: 1em;
    margin-bottom: 0.5rem;
}

.inner h4:first-child {
    margin-top: 0;
}

.inner div {
    margin-bottom: 1rem;
    color: black;
    text-decoration: none;

    &.more {
        color: #2653d9;
        margin-bottom: 0;
    }
}

.inner a.nav {
    color: inherit;
    text-decoration: none;
}
