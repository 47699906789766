.spinner {
    color: #2653d9;
    margin-left: 1em;
    margin-top: 0.3em;
}

.add {
    margin: 1em 0 2em;
}

.head {
    h2 {
        width: auto;
        display: inline-block;
        font-size: 18px;
        line-height: 24px;
    }
}
